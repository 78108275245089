/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

import { _getDocumentLocale, betterTime } from './formatters-internal.js';

// Override the dateRange internal formatter to apply this fix:
// https://github.com/yext/answers-hitchhiker-theme/commit/f9438a791dc3a2eae5051ac3b289c2de9fe0319c
export function dateRange(
  profile,
  key = 'time',
  dateFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
  separator = '-') {

  const dateField = profile[key];
  if (!dateField) {
    console.error('could not find ', key, profile);
    return null;
  }

  if (!(dateField.start || dateField.end)) {
    console.error(key, 'is empty', profile);
    return null;
  }

  const locale = _getDocumentLocale();
  const start = betterTime(dateField.start);
  const end = betterTime(dateField.end);
  const startString = start.toLocaleString(locale, dateFormatOptions);
  let endString = end.toLocaleString(locale, dateFormatOptions);

  if (startString && endString) {
    if (start.toLocaleDateString() === end.toLocaleDateString()) {
      endString = end.toLocaleString(locale, {
        ...dateFormatOptions,
        month: undefined,
        day: undefined,
        year: undefined,
      });
    }

    return `${startString} ${separator} ${endString}`;
  }

  if (startString) {
    return startString;
  }

  return endString;
}
